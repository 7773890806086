export default {
    language: {
        name: 'English'
    },
    sidebar: {
        home: 'Data Overview',
        endbenutzer: 'User List',
        plant: 'Scene',
        plant_plant: 'Plant',
        device: 'Device List',
        datalog: 'Datalog ',
        inverter: 'Inverter ',
        sotrage: 'Sotrage ',
        battery: 'Battery ',
        socket: "Smaet Plug",
        agent: 'Agent ',
        systematicSupply: 'Delivery Log',
        firmware: 'Firmware ',
        firmware_up: 'Firmware Upload',
        firmware_log: 'Upgrade Records',
        system: 'Settings',
        system_datalog_log: 'Datalog Logs',
        system_log: 'Operational Logs',
        system_tongxun: 'Newsletter Logs',
        system_app: 'App Version',
        system_limits: 'Limits',
        system_menu: 'Menu',
        system_company: 'System Company',

        personal: 'User Information',
        logout: 'logout',
        charger: "Charging List",
        mater: 'Electricity Meter'
    },
    login: {
        email: "Email",
        password: "Password",
        remember: "Remember Email and password",
        enteremail: "Please enter email",
        enterpassword: "Please enter password",
        iot: "iot Vulnerability Disclosure",
        Cookies: "Cookies are provided by us and some browsers on our website. Cookies are necessary for the operation of the website. By continuing to use cookies, you agree to the use of cookies.",
        agree: "agree",
        refuse: "refuse",


    },
    home: {
        echarts1: {
            title: 'Overview of end-user data',
            subhead: `Total number of new/users added today`,
            title: "Registe User",
        },
        echarts2: {
            title: 'End user online overview',
            subhead: `Total number of logins/users today`,
            title: "Login User",
        },
        echarts3: {
            title: 'Overview of agent data',
            subhead: `Today login/total number of agents`,
            title: "Login Agent",
        },
        echarts4: {
            title: 'Scene Data Overview',
            subhead: 'New Scenes/Total Number of Scenes',
            title: 'Add Scene',

            title_plant: "Plant Data Overview",
            subhead_plant: "New Plants/Total Plants",
            title_plant: "Add Plant",
        },
        echarts5: {
            title: 'Overview of the datalog data',
            subhead: `The total number of new datalog/Datalog`,
            title: "Add Datalog",
        },
        echarts6: {
            title: 'Overview of device data',
            subhead: `New Device/total number of device`,
            title: "Add Device",
        },
    },

    End_user: {
        input_email: "Please enter the user email",
        btn_search: "Search",
        btn_add: "Add",
        btn_delete: "Delete",
        btn_binding: "Binding",

        add_station: "Add Scene",

        lable_StationName: "Scene Name",
        lable_StationType: "Scene Type",
        lable_PVCapacity: "PV Capacity",
        lable_InstalltionDate: "Installtion Date",
        lable_Country: "Country",
        lable_City: "City",
        lable_Address: "Address",
        lable_TimeZone: "Time Zone",
        lable_Energyprice: "Energy price",
        lable_IncomeUnit: "Income Unit",

        input_StationName: "Please enter Scene name",
        input_StationType: "Please select type",
        input_PVCapacity: "Please enter capacity",
        input_InstalltionDate: "Please select date",
        input_Country: "Please select country",
        input_City: "Please select city",
        input_Address: "Please enter address",
        input_TimeZone: "Please select time zone",
        input_Energyprice: "Please enter energy price",
        input_IncomeUnit: "Please select unit",

        max_20: "The maximum size is 20 digits",
        max_5: "The maximum size is 5 digits",
        max_8: "The maximum size is 8 digits",
        format: "Please enter the correct format",

        close: "Close",
        submit: "Submit",

        title_delete: "Delete？",
        no: "No",
        yes: "Yes",

        Bingding_Agent: "Bingding Agent",
        Agent_Code: "Agent Code:",
        please_select: "please select",
    },

    plant: {
        input_email: "Please enter the user email",


        search: "Search",
        edit: "Edit",
        delete: "Delete",

        edit_plant: "Edit scene",
        input_station: "Please enter scene name",
        lable_StationName: "Scene name",
        lable_StationType: "Scene type",




        lable_PVCapacity: "PV Capacity",
        lable_InstalltionDate: "Installtion Date",
        lable_Country: "Country",
        lable_City: "City",
        lable_Address: "Address",
        lable_TimeZone: "Time Zone",
        lable_Energyprice: "Energy price",
        lable_IncomeUnit: "Income Unit",

        input_StationName: "Please enter Scene name",
        input_StationType: "Please select type",
        input_PVCapacity: "Please enter capacity",
        input_InstalltionDate: "Please select date",
        input_Country: "Please select country",
        input_City: "Please select city",
        input_Address: "Please enter address",
        input_TimeZone: "Please select time zone",
        input_Energyprice: "Please enter energy price",
        input_IncomeUnit: "Please select unit",

        max_20: "The maximum size is 20 digits",
        max_5: "The maximum size is 5 digits",
        max_8: "The maximum size is 8 digits",
        format: "Please enter the correct format",

        close: "Close",
        submit: "Submit",

        title_delete: "Delete？",
        no: "No",
        yes: "Yes",

    },
    datalog: {
        email: "Please enter the user email",
        datalogSn: "Please enter the WiFi SN",
        search: "Search",
        Version: "Version",
        setting: "Setting",
        upgrade: "Upgrade",
        delete: "Delete",

        title_datalog: "Datalog setting",

        span_info: "Information",
        span_datalog: "Datalog SN",
        span_name: "Another name",
        span_time: "Update time",
        span_firmware: "Signal/firmware version",

        span_order: "Order",
        span_settingdns: "DNS Setting",
        span_inputsetting: "Please enter DSN",
        span_upgrade: "Upgrade Datalog",
        span_reset: "Reset Datalog",

        span_setup: "Advanced Setup",
        span_param: "Param No",
        span_value: "Value",

        btn_setup: "Advanced Setup",
        btn_setting: "Setting",
        btn_settingdns: "DNS Setting",
        btn_upgrade: "Upgrade",
        btn_reset: "Reset",
        btn_read: "Read",

        btn_close: "Close",


        methods_version: "Detect version update ?",
        no: "NO",
        yes: "YES",
        methods_delete: "Delete?",
        methods_rereset: "Reset?",

        methodes_inputDns: "Please enter a domain name",
        methodes_DNS: "Whether this domain name is set",
    },
    sotrage: {
        select: "Please select",
        email: "Please enter the user email",
        datalogSn: "Please enter the datalog SN",
        deviceSn: "Please enter the device SN",
        search: "Search",

        Function_enable: "Function enable",
        Model_number: "Model number",
        output_power: "Output power",
        individual_address: "Individual address",
        Set_number: "Set serial number",
        Set_time: "Set time",
        Set_Multiple_addresses:"Multiple addresses",


    },
    device: {
        select: "Please select",
        email: "Please enter the user email",
        datalogSn: "Please enter the datalog SN",
        deviceSn: "Please enter the device SN",
        search: "Search",

        title: "Inverter Setting",
        setting: "Setting",
        setting_inverte: "Setting Inverter Param:",
        read_data_device: "Read inverter parameters",

        input_start_address: "Start Address",
        input_ent_address: "Stop Address",
        input_setting_value: "Please enter the setting value",

        read_data: "Read register data:",
        input_start_read: "Start register address",
        input_end_read: "Address of the termination register",

        btn_address: "Setting address ",
        btn_value: "set up parameters",
        btn_read: "Read",
        btn_close: "Close",

        selet_data: "Select Date",

    },
    battery: {
        select: "Please select",
        email: "Please enter the user email",
        datalogSn: "Please enter the datalog SN",
        deviceSn: "Please enter the device SN",
        search: "Search"
    },

    socket: {
        title: "Socket Setting",
        setting: "Setting",
        span: " If multiple register addresses are set, multiple values are set. Set values separated by, example (1,2,3)",
        setting_device: "Set parameters:",
        startaddress: "Start address",
        stopaddress: "Stop address",
        value: "Value",
        readdata: "Read the register data:",
        setting_address: "Setting address",
        setting_value: "Set up",
        read: "Read",
        return: "Close",



    },





    agent: {
        close: "Close",
        submit: "Submit",
        input_email: "Please enter the user email",
        search: "Search",
        import_device: "import Device",
        edit: "Edit",

        add_agent: "Add Agent",

        title: "Add Agent",
        email: "Email",
        password: "Password",
        phone: "Phone",
        company: "Company",
        language: "Language",
        address: "Address",


        input_email: "Please enter password",
        input_password: "Please enter password",
        input_phone: "Please enter phone",
        input_company: "Please select company",
        input_language: "Please enter language",
        input_address: "Please enter address",

        relus_email: "Please enter a valid email address",


        title_edit: "Edit Agent",

        title_device: "Upload",
        span: "Example: Excle table ",
        btn_upload: "Upload",

        delete: "Delete",
        agent_delete: "Delete agent ?"
    },




    ship: {
        starttime: "Start time",
        endtime: "End time",
        deviceSn: "Please enter the device SN",
        select: "Please select company",
        search: "Search"

    },


    Firmware: {
        file_name: "Please enter file name",
        search: "Search",
        add: "Add",
        delete: "Delete",
        title: "Upload File",
        select_folder: "Select folder:",
        select_folder_type: "Select a folder type",
        folder_name: "Folder name:",
        input_folder_name: "Please enter file name",
        craete_folder: "Create a new folder",
        folder_path: "Folder path：",
        create: "Create Folder",
        listed_files: "Listed files：",
        delete: "Delete",

        import_folder: "Import file",
        uplad_folder: "Upload files",
        close: "Close",
    },


    Upgrade: {
        starttime: "Start time",
        endtime: "End time",
        deviceSn: "Please enter the device SN",
        type: "Please select type",
        search: "Search",

        datalog: "Datalog",
        Inverter: "Inverter",
        Control: "Control",

    },

    datalogs_logs: {
        starttime: "Start time",
        endtime: "End time",
        deviceSn: "Please enter the device SN",
        Version: "Please enter the version",
        search: "Search"

    },
    Logs: {
        starttime: "Start time",
        endtime: "End time",
        deviceSn: "Please enter the device SN",
        type: "Please select command type",
        search: "Search",


    },
    Special: {
        starttime: "Start time",
        endtime: "End time",

        deviceSn: "Please enter the device SN",
        datalogSn: "Please enter the datalog SN",
        type: "Please select command type",
        search: "Search",
        save: "Save",
        Examples: "Examples：(2305210230,2305210139)",

    },
    App: {
        System_Config: "System Config",
        APP_Version: "APP Version Release",
        Android_Version: "Android Version:",
        IOS_Version: "IOS Version:",
        APP_Type: "APP Type:",
        New_Version: "New Version:",
        update_content: "Update Content Description:(use ‘ /n ’ divide each point )",
        Whether_update: "Whether to force update:",
        no: "NO",
        yes: "YES",
        submit: "Submit",
        projectType: "Project Type",
        VersionType: "Version Type",
    },

    Tips: {
        app_submit: "Make sure to modify the information?",
        firmware_delete: "Confirm deletion?",
        firmware_deleteCDN: "Delete files in CDN?",
        yes: "Yes",
        no: "No",
    },

    global: {
        DataDetails: "Data Details",
        device: "Inverter Settings",
        device_data: "Set Inverter Parameters",
        read_data: "Read inverter parameters",
        storage: "Energy Storage Settings",
        storage_data: "Set Energy Storage Parameters",
        storage_read: "Read energy storage parameters",

        battery: "Battery Settings",
        battery_data: "Set Battery Parameters",

        socket: "Socket Settings",
        socket_data: "Set Socket Parameters",
        Read_Write: "Read/Write data",
        select: "Please select",
        value: "Value",
        password: "Password settings",
        enter: "Enter password",
        number: "Serial number",
        enternumber: "Enter serial number or select to read serial number",
        resetdatalog: "Restart Data Collector",

        reset: "Restart",


        read:"Read",
        setting:"Setting",
        read_battery_data:"Read battery data",

        bms_sanke_Li: {    
            time: "BMS Time",    
            serialNumber: "BMS Serial Number",    
            batterySerialNumber: "Battery Serial Number",    
            chargeDischargeLimits: "Charge and Discharge Limits",    
            shieldProtection: "Shield Protection",    
            thresholdCurrent: "Threshold Current",    
            cycleCount: "Cycle Count",    
            modifyProtocol: "Modify Protocol",    
            overallOvervoltageAlarm: "Overall Overvoltage Alarm",    
            overallOvervoltageProtection: "Overall Overvoltage Protection",    
            overallOvervoltageProtectionRecover: "Overall Overvoltage Protection Recovery",    
            overallOvervoltageProtectionDelay: "Overall Overvoltage Protection Delay",    
            singleCellOvervoltageAlarm: "Single Cell Overvoltage Alarm",    
            singleCellOvervoltageProtection: "Single Cell Overvoltage Protection",    
            singleCellOvervoltageProtectionRecover: "Single Cell Overvoltage Protection Recovery",    
            singleCellOvervoltageProtectionDelay: "Single Cell Overvoltage Protection Delay",    
            overallOverdischargeAlarm: "Overall Overdischarge Alarm",    
            overallOverdischargeProtection: "Overall Overdischarge Protection",    
            overallOverdischargeProtectionRecover: "Overall Overdischarge Protection Recovery",    
            overallOverdischargeProtectionDelay: "Overall Overdischarge Protection Delay",    
            singleCellOverdischargeAlarm: "Single Cell Overdischarge Alarm",    
            singleCellOverdischargeProtection: "Single Cell Overdischarge Protection",    
            singleCellOverdischargeProtectionRecover: "Single Cell Overdischarge Protection Recovery",    
            singleCellOverdischargeProtectionDelay: "Single Cell Overdischarge Protection Delay",    
            chargeOvercurrentAlarm: "Charge Overcurrent Alarm",    
            chargeOvercurrentProtection: "Charge Overcurrent Protection",    
            chargeOvercurrentProtectionDelay: "Charge Overcurrent Protection Delay",    
            chargeOvercurrentSecondProtection: "Charge Overcurrent Second Protection",    
            chargeOvercurrentSecondProtectionDelay: "Charge Overcurrent Second Protection Delay",    
            dischargeOvercurrentAlarm: "Discharge Overcurrent Alarm",    
            dischargeOvercurrentProtection: "Discharge Overcurrent Protection",    
            dischargeOvercurrentProtectionDelay: "Discharge Overcurrent Protection Delay",    
            dischargeOvercurrentSecondProtection: "Discharge Overcurrent Second Protection",    
            dischargeOvercurrentSecondProtectionDelay: "Discharge Overcurrent Second Protection Delay",    
            shortCircuitProtectionCurrent: "Short Circuit Protection Current",    
            shortCircuitProtectionDelay: "Short Circuit Protection Delay",    
            highTemperatureChargingAlarm: "High Temperature Charging Alarm",    
            highTemperatureChargingProtection: "High Temperature Charging Protection",    
            highTemperatureChargingProtectionRecover: "High Temperature Charging Protection Recovery",    
            highTemperatureDischargingAlarm: "High Temperature Discharging Alarm",    
            highTemperatureDischargingProtection: "High Temperature Discharging Protection",    
            highTemperatureDischargingProtectionRecover: "High Temperature Discharging Protection Recovery",    
            lowTemperatureChargingAlarm: "Low Temperature Charging Alarm",    
            lowTemperatureChargingProtection: "Low Temperature Charging Protection",    
            lowTemperatureChargingProtectionRecover: "Low Temperature Charging Protection Recovery",    
            lowTemperatureDischargingAlarm: "Low Temperature Discharging Alarm",    
            lowTemperatureDischargingProtection: "Low Temperature Discharging Protection",    
            lowTemperatureDischargingProtectionRecover: "Low Temperature Discharging Protection Recovery",    
            powerTubeHighTemperatureAlarm: "Power Tube High Temperature Alarm",    
            powerTubeHighTemperatureProtection: "Power Tube High Temperature Protection",    
            powerTubeHighTemperatureProtectionRecover: "Power Tube High Temperature Protection Recovery",    
            environmentHighTemperatureAlarm: "Environment High Temperature Alarm",    
            environmentHighTemperatureProtection: "Environment High Temperature Protection",    
            environmentHighTemperatureProtectionRecover: "Environment High Temperature Protection Recovery",    
            environmentLowTemperatureAlarm: "Environment Low Temperature Alarm",    
            environmentLowTemperatureProtection: "Environment Low Temperature Protection",    
            environmentLowTemperatureProtectionRecover: "Environment Low Temperature Protection Recovery",    
            balancingStartVoltage: "Balancing Start Voltage",    
            balancingStartVoltageDifference: "Balancing Start Voltage Difference",    
            fullChargeVoltage: "Full Charge Voltage",    
            fullChargeCurrent: "Full Charge Current",    
            singleCellLowVoltageSleep: "Single Cell Low Voltage Sleep Voltage",    
            singleCellLowVoltageSleepDelay: "Single Cell Low Voltage Sleep Delay",    
            standbySleepDelay: "Standby Sleep Delay",    
            lowBatteryAlarmValue: "Low Battery Alarm Value",    
            overchargeProtectionCapacityRatioRelease: "Overcharge Protection Capacity Ratio Release",    
            heatingFilmStartTemperature: "Heating Film Start Temperature",    
            heatingFilmStopTemperature: "Heating Film Stop Temperature"    
          },
          TouchSetting:"Transparent Transmission",

    },
    btn: {
        setting_address: "Set Address",
        close: "Close",
        submit: "Submit",
        Setting_parameters: "Setting Parameters",
        Reading: "Reading",
        search: "Search",

        add: "Add",
        import: "Import",
        download: "Sample Documentation",
        edit: "Edit",
        login: "Login",
        export: "export",
        agent: "Agent",
        menu: "Menu",

        limits: "Save Allocation",
        AddZ: "Add Main Menu",

    },
    form:
    {
        addShip: "Add Shipping Device",
        editShip: "Edit Shipping Device",
        import: "Import device information",
        shipSN: "Shipping Serial Number",
        agent: "Agent",
        networkCall: "Execute Network Callback",
        warrantyPeriod: "Warranty Period",
        shiptime: "Shipping Time",
        shiptype: "Shipping Type",
        classify: "Classification",
        file: "Drag file here or click to upload",


        edit_plant: "Edit Scenario",
        input_station: "Enter Scenario Name",
        lable_StationName: "Scenario Name",
        lable_StationType: "Scenario Type",
        input_StationName: "Enter Scenario Name",

        edit_plant_plant: "Edit Power Plant",
        input_station_plant: "Enter Power Plant Name",
        lable_StationName_plant: "Power Plant Name",
        lable_StationType_plant: "Power Plant Type",
        input_StationName_plant: "Enter Power Plant Name"
    },
    placeholder: {
        PshipSN: "Enter shipping serial number",
        Pagent: "Enter agent code",
        PnetworkCall: "Confirm network callback",
        PwarrantyPeriod: "Confirm warranty period",
        Pshiptime: "Confirm shipping time",
        Pshiptype: "Confirm shipping type",
        Pshiptype2: "Confirm shipping type",
        Pclassify: "Confirm classification",
        placeholdertype:"Please select the type"
    },
    limits: {
        AddRoles: "Add Roles",
        nametip: "Please enter the role name",
        remark: "Please enter remarks",
        EditRoles: "Edit Role",
        RolesAgent: "Agent Bound to Roles",
        RoleName: "Role Name:",
        Remark: "Remark",
    },
    Menu: {
        AddC: "Add Menu",
        EditC: "Edit Menu",

        MenuName: "Menu Name",
        MenuNameTip: "Please enter the menu name",
        url: "URL",
        urlTip: "Please enter the URL",
        MenuType: "Menu Type",
        MenuTypeTip: "Please select the menu type",
        Remark: "Remark",
        RemarkTip: "Please enter remark information",

    }, info: {
        Basic_Information: "Basic Information",
        User_name: "User Name",
        Company: "Company",
        Adress: "Address",
        Phone: "Phone Number",
        Change_Password: "Change Password",
        New_Password: "New Password",
        Confirm_Password: "Confirm Password",
        Old_Password: "旧密码",

        Update_Acknowledge: "Confirm Update",
        tip1: "Please enter your password",
        tip2: "Please re-enter your password",
        tip3: "The passwords do not match!",
        tip4: "This operation is irreversible, please confirm your information",
    },
}